main {
  position: relative;
}

.no-impl {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: lightgray;
  z-index: 9999999999999;
  opacity: 0.5;
}

ctc,
ctc ctcspan {
  pointer-events: none;
  text-decoration: none !important;
}
ctc i {
  display: none;
}

.clickToCall {
  display: none;
}

.clickToCall ctc ctcspan {
  pointer-events: auto;
}

#google_translate_element {
  margin: 20px 0;
}

.goog-te-combo {
  box-shadow: none;
  background: #fff;
  color: #5b5e81;
  line-height: 1.5;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #d5dfe7;
  appearance: none;
  padding: 0.3rem 1rem;
  font-weight: 400;
  margin-bottom: -16px !important;
  margin-top: 10px !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  height: 100%;
}

.goog-te-gadget > span {
  display: none !important;
}
